"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var runtype_1 = require("../runtype");
var util_1 = require("../util");
var show_1 = require("../show");
/**
 * Construct a record runtype from runtypes for its values.
 */
function InternalRecord(fields, isReadonly) {
    return withExtraModifierFuncs(runtype_1.create(function (x, visited) {
        if (x === null || x === undefined) {
            var a = runtype_1.create(function (_x) { return ({ success: true, value: _x }); }, { tag: 'record', fields: fields });
            return { success: false, message: "Expected " + show_1.default(a) + ", but was " + x };
        }
        for (var key in fields) {
            var validated = runtype_1.innerValidate(fields[key], util_1.hasKey(key, x) ? x[key] : undefined, visited);
            if (!validated.success) {
                return {
                    success: false,
                    message: validated.message,
                    key: validated.key ? key + "." + validated.key : key,
                };
            }
        }
        return { success: true, value: x };
    }, { tag: 'record', isReadonly: isReadonly, fields: fields }));
}
exports.InternalRecord = InternalRecord;
function Record(fields) {
    return InternalRecord(fields, false);
}
exports.Record = Record;
function withExtraModifierFuncs(A) {
    A.asReadonly = asReadonly;
    return A;
    function asReadonly() {
        return InternalRecord(A.fields, true);
    }
}
