"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var runtype_1 = require("../runtype");
var util_1 = require("../util");
var show_1 = require("../show");
/**
 * Construct a runtype for partial records
 */
function Part(fields) {
    return runtype_1.create(function (x, visited) {
        if (x === null || x === undefined) {
            var a = runtype_1.create(function (_x) { return ({ success: true, value: _x }); }, { tag: 'partial', fields: fields });
            return { success: false, message: "Expected " + show_1.default(a) + ", but was " + x };
        }
        for (var key in fields) {
            if (util_1.hasKey(key, x) && x[key] !== undefined) {
                var validated = runtype_1.innerValidate(fields[key], x[key], visited);
                if (!validated.success) {
                    return {
                        success: false,
                        message: validated.message,
                        key: validated.key ? key + "." + validated.key : key,
                    };
                }
            }
        }
        return { success: true, value: x };
    }, { tag: 'partial', fields: fields });
}
exports.Part = Part;
exports.Partial = Part;
